<template>
  <v-container>
    <v-data-table
        dense
        :headers="headers"
        :items="list"
        item-key="name"
        class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" v-on:click="showDetail(item)">
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:item.action2="{ item }">
        <v-switch
            v-model="item.status"
            @change="changeActive(item)"
        ></v-switch>
      </template>
      <template v-slot:no-data>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {GET_LIST_AFFILIATE_CODE, UPDATE_STATUS_ALL_AFFILIATE_CODE} from "@/store/affiliateCode.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  name: "list",
  data: () => ({
    headers: [
      { text: "Mã giới thiệu", value: "code_number" },
      { text: "Học viên", value: "full_name" },
      { text: "Email", value: "email" },
      { text: "", value: "action" },
      { text: "", value: "action2" }
    ]
  }),
  computed: {
    list() {
      return this.$store.getters.listAffiliates;
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Affiliate",titledad:'Affiliate' }]);
    this.getList();
  },
  methods: {
    changeActive(item) {
      let payload = {
        code: item.code_number,
        status: item.status,
      }
      this.$store.dispatch(UPDATE_STATUS_ALL_AFFILIATE_CODE, payload);
    },
    showDetail(item) {
      this.$router.push({
        path: "/afflificate/detail",
        query: {
          affiliate_code: item.code_number,
          user_id : item.id
        }
      });
    },
    getList() {
      var payload = { page: this.page, page_count: this.itemsPerPage };
      this.$store.dispatch(GET_LIST_AFFILIATE_CODE, payload);
    }
  }
};
</script>

<style scoped></style>
